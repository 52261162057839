type Config = {
  AMPLIFY_IDENTITY_POOL_ID: string;
  AMPLIFY_USER_POOL_ID: string;
  AMPLIFY_USER_POOL_WEB_CLIENT_ID: string;

  API_HOST_URL: string;
  API_KEY: string;

  ENV_NAME: string;
  EVENT_ID: string;

  POINTR_BUILDING_INTERNAL_ID: number;
  POINTR_INITIAL_POI: string;
  POINTR_SITE_INTERNAL_ID: number;
};

const fallbackValues = {
  POINTR_BUILDING_INTERNAL_ID: 7,
  POINTR_SITE_INTERNAL_ID: 3,
  POINTR_INITIAL_POI: 'poi7224421852002181',
};

const config: Config = {
  AMPLIFY_IDENTITY_POOL_ID:
    process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID ?? '',
  AMPLIFY_USER_POOL_ID: process.env.REACT_APP_AMPLIFY_USER_POOL_ID ?? '',
  AMPLIFY_USER_POOL_WEB_CLIENT_ID:
    process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID ?? '',

  API_HOST_URL: process.env.REACT_APP_API_HOST_URL ?? '',
  API_KEY: process.env.REACT_APP_API_KEY ?? '',

  ENV_NAME: process.env.REACT_APP_ENV_NAME ?? '',
  EVENT_ID: process.env.REACT_APP_EVENT_ID ?? '',

  POINTR_BUILDING_INTERNAL_ID:
    Number(process.env.REACT_APP_POINTR_BUILDING_INTERNAL_ID) ||
    fallbackValues.POINTR_BUILDING_INTERNAL_ID,
  POINTR_INITIAL_POI:
    process.env.REACT_APP_POINTR_INITIAL_POI ??
    fallbackValues.POINTR_INITIAL_POI,
  POINTR_SITE_INTERNAL_ID:
    Number(process.env.REACT_APP_POINTR_SITE_INTERNAL_ID) ||
    fallbackValues.POINTR_SITE_INTERNAL_ID,
};

export { config };
