import AWS from 'aws-sdk';
import { CredentialsOptions } from 'aws-sdk/lib/credentials';
import { AwsClient } from 'aws4fetch';

import { config } from '../config';
import { RemoteConfig } from '../hooks/UseRemoteConfig';
import { CatalogEntry, PlaceJSON, FaqJSON, SessionJSON } from './CatalogEntry';
import faqData from './faq.json';
import placeData from './places.json';
import sessionData from './sessions.json';


const { AMPLIFY_IDENTITY_POOL_ID, API_HOST_URL, API_KEY, EVENT_ID } = config;
const REGION = 'us-east-1';

// Initialize the Amazon Cognito credentials provider
AWS.config.region = REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: AMPLIFY_IDENTITY_POOL_ID,
});

const getAwsClient = async () => {
  const credentials = await getAWSCredentials();
  if (credentials) {
    return new AwsClient({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: REGION,
      service: 'execute-api',
    });
  }
};

const getAWSCredentials = () => {
  return new Promise<AWS.Credentials | CredentialsOptions | null | undefined>(
    (resolve, reject) => {
      AWS.config.getCredentials((error) => {
        if (error) reject(error);
        else {
          resolve(AWS.config.credentials);
        }
      });
    },
  );
};

const dataSortingComparisonFunction = (a: CatalogEntry, b: CatalogEntry) =>
  ((a.abbreviation ?? a.question ?? a.title) as string).localeCompare(
    (b.abbreviation ?? b.question ?? b.title) as string,
  );

const getAllLocalData = (): CatalogEntry[] => {
  return [
    ...getLocalFAQData(),
    ...getLocalSessionData(),
    ...getLocalPlaceData(),
  ].sort(dataSortingComparisonFunction);
};

const getLocalFAQData = (): CatalogEntry[] => {
  return faqData.questions.map((q) => {
    return { ...q, catalogType: 'FAQ' };
  });
};

const getLocalSessionData = (): CatalogEntry[] => {
  return sessionData.data.catalogData.session.map((s) => {
    return { ...s, catalogType: 'Session' } as Record<string, unknown>;
  }) as CatalogEntry[];
};

const getLocalPlaceData = (): CatalogEntry[] => {
  return placeData.map((p) => {
    return { ...p, catalogType: 'Place' };
  });
};

const getAllApiData = async (): Promise<CatalogEntry[]> => {
  try {
    const response = await Promise.all([
      getApiFAQData(),
      getApiSessionData(),
      getApiPlaceData(),
    ]);
    return [...response[0], ...response[1], ...response[2]].sort(
      dataSortingComparisonFunction,
    );
  } catch {
    return [];
  }
};

async function awsGET<ResponseJSON>(
  request: RequestInfo,
  responseJSON?: ResponseJSON,
): Promise<ResponseJSON | undefined> {
  if (responseJSON) {
    return responseJSON;
  }
  const awsClient = await getAwsClient();
  if (awsClient) {
    const response = await awsClient.fetch(
      `${API_HOST_URL}/kiosk/${EVENT_ID}${request}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
          Host: API_HOST_URL,
        },
      },
    );
    const json = (await response.json()) as unknown;
    return json as ResponseJSON;
  }
}

const getApiFAQData = async (): Promise<CatalogEntry[]> => {
  try {
    const json = await awsGET<FaqJSON>('/faq.json');
    return (
      json?.questions.map(
        (q): CatalogEntry => ({ ...q, catalogType: 'FAQ' }),
      ) ?? []
    );
  } catch {
    return [];
  }
};

const getApiSessionData = async (): Promise<CatalogEntry[]> => {
  try {
    const json = await awsGET<SessionJSON>('/sessions.json');
    return (
      json?.data.catalogData.session.map(
        (s): CatalogEntry => ({ ...s, catalogType: 'Session' }),
      ) ?? []
    );
  } catch {
    return [];
  }
};

const getApiPlaceData = async (): Promise<CatalogEntry[]> => {
  try {
    const json = await awsGET<PlaceJSON>('/places.json');
    return (
      json?.map((p): CatalogEntry => ({ ...p, catalogType: 'Place' })) ?? []
    );
  } catch {
    return [];
  }
};

const getRemoteConfigData = async (): Promise<RemoteConfig | null> => {
  try {
    return (await awsGET<RemoteConfig>('/app-config.json')) ?? null;
  } catch {
    return null;
  }
};

export { getAllLocalData, getAllApiData, getRemoteConfigData };
